
  import { getCategoriesListByProps, getBlogPostsListByProps, findComponentOnPage } from '@/utils/components';
  import { errorHandler } from '@/utils/error-handler';
  import { encodeOpts } from '@/utils/encode-opts';
  import { checkPageRedirect } from '@/utils/redirect';

  import { getSpecialListByProps } from '../utils/components/specialList';

  const NEWRELIC_APP_NAME = process.env.NEWRELIC_APP_NAME;
  const API_URL = process.env.API_URL;
  export default {
    name: 'HomeComponent',
    // serverCacheKey() {
    //   return `HomeComponent_${Math.floor(Date.now() / 100000)}`;
    // },
    components: {
      page: () => import('@/components/pageGenerator'),
    },
    watchQuery: true,
    async asyncData(context) {
      try {
        const debugDetails = [{ service: process.env.NEWRELIC_APP_NAME, spent: Date.now() }];
        let urlSlug = context.route.params.pathMatch;
        const {
          storeUrl,
          merchantId,
          _id,
          dontAllowOrdersAfterDate,
          ...store
        } = context.store.getters.storeInformation;
        const { query } = context.route;
        let pageData; //= { page: [], tags: {}, data: {} };
        if (
          [
            'sample',
            'order',
            'artproof',
            'quickquote',
            'designer',
            'configureproduct',
            'products',
            'product',
            'presentation',
            'largequote',
            'supplier',
          ].includes(urlSlug.toLowerCase()) ||
          context.route.path.startsWith('/facetgroup')
        ) {
          throw 'Not found';
        }
        if (['checkout'].includes(urlSlug.toLowerCase()) && !context.store.getters['cart/orders'].length) {
          return context.redirect(302, '/cart');
        }
        if (!urlSlug.includes('xml') && !urlSlug.includes('txt')) {
          if (!pageData) {
            const bodyRequest = {
              storeUrl,
              urlSlug,
              query,
              params: { keys: ['categories'] },
            };
            let storePage, storeTags;
            pageData = { page: [], tags: {}, data: {} };

            let details = [];
            if (!storePage) details.push(context.$info.storePages.getStorePageInformation(context, bodyRequest));
            if (!storeTags) details.push(context.$info.storePages.getStorePageHeadTags(context, urlSlug, null, query));
            if (details.length) {
              const res = await Promise.all(details);
              if (!storePage) storePage = res[0];
              if (!storeTags) storeTags = res[1] || res[0];
            }

            if (checkPageRedirect(context, storePage)) return;

            pageData.urlSlug = urlSlug;
            pageData.page = storePage.pageComponents;
            pageData.categories = storePage.categories;
            pageData.tags = storeTags;

            const supplierList = findComponentOnPage('SuppliersList', pageData.page);
            if (supplierList) {
              const params = { withProducts: true };
              if (supplierList.props.suppliersList && supplierList.props.suppliersList.showPendingStatus) {
                params.filter = { status: [{ val: 2 }, { val: 3 }] }
              } else {
                params.filter = { status: [{ val: 3 }] }
              }
              const suppliers = await context.$api.suppliers.getSuppliers(params)
              if (suppliers) {
                pageData.data.suppliers = suppliers
              }
            }

            const categoriesList = findComponentOnPage('CategoriesList', pageData.page); //all-products
            if (categoriesList) {
              const categories = await getCategoriesListByProps(
                _id,
                categoriesList.props,
                context.$api.stores.getCategories
              );
              pageData.data.categoriesList = categories;
              if (storePage.categories?.length)
                pageData.data.categoriesList.push(
                  ...storePage.categories.filter((e) => !pageData.data.categoriesList.find((f) => f._id === e._id))
                );
            }

            const blogPostsList = findComponentOnPage('BlogPostsList', pageData.page);
            if (blogPostsList) {
              const { data: posts, pagination, ...remain } = await getBlogPostsListByProps(
                _id,
                blogPostsList.props,
                pageData,
                context.$api.stores.getStorePages,
                {
                  urlSlug: urlSlug.toLowerCase().split('/')[urlSlug.toLowerCase().split('/').length - 1],
                  getThemeCategories: context.$api.stores.getThemeCategories,
                },
                query.page || 1,
                'createdDate',
                query
              );
              pageData.data.blogList = posts;
              pageData.data.pagination = pagination;
            }

            const specialsList = findComponentOnPage('SpecialsList', pageData.page);
            if (specialsList) {
              const { data: specialList, ...remain } = await getSpecialListByProps(
                _id,
                specialsList.props,
                context.$api.stores.getStoreSpecials,
                'createdDate'
              );
              pageData.data.specialList = specialList;
            }

            const listPagesByCategory = findComponentOnPage('ListPagesByCategory', pageData.page);
            if (listPagesByCategory) {
              pageData.data.templateCategories = listPagesByCategory.props.categories;
            }

            const countdownData = findComponentOnPage('Countdown', pageData.page);
            if (countdownData && dontAllowOrdersAfterDate) {
              pageData.data.countdownData = {
                ...countdownData.props.countdownData,
                deadline: dontAllowOrdersAfterDate,
                isDatePassed: context.store.getters.isDatePassed,
              };
            }

            const facetsList = findComponentOnPage('FacetsList', pageData.page);
            if (facetsList) {
              pageData.data.facets = (
                await context.$axios.get(`/facetGroups/facets`, {
                  params: {
                    facetGroups: facetsList.props.facetsList.facetGroups.map((el) => el.id),
                    storeUrl,
                  },
                  paramsSerializer: encodeOpts,
                })
              ).data;
            }

            const userComponent = findComponentOnPage('AccountManager', pageData.page);
            if (userComponent) {
              const user = await context.$api.users.getUserById(userComponent.props.userId);
              pageData.data.user = user;
            }

            const addServerOptIn = async () => {
              const { orderId } = context.route.query;
              const customer = (await context.$axios.get(`/orders/${orderId}/customers`)).data;
              const gtins = (await context.$axios.get(`/orders/${orderId}/gtins`)).data.filter((g) => g);
              const etaDate = new Date(Date.now() + 10 * 24 * 60 * 60 * 1000);
              const year = etaDate.getFullYear();
              const month = String(etaDate.getMonth() + 1).padStart(2, '0');
              const day = String(etaDate.getDate()).padStart(2, '0');
              const formattedEtaDate = `${year}-${month}-${day}`;
              pageData.tags.script.push({
                src: 'https://apis.google.com/js/platform.js?onload=renderOptIn',
                defer: true,
                async: true,
              });
              pageData.tags.script.push({
                innerHTML: `window.renderOptIn = function() {
              window.gapi.load('surveyoptin', function() {
                window.gapi.surveyoptin.render(
                  {
                    "merchant_id": "${merchantId}",
                    "order_id": "${orderId}",
                    "email": "${customer.email}",
                    "delivery_country": "US",
                    "estimated_delivery_date": "${formattedEtaDate}",
                    "products": [${gtins.map((g) => JSON.stringify({ gtin: g }))}]
                  });
              });
            }`,
              });
            };
            if (urlSlug === 'checkoutconfirmation' || urlSlug === 'paymentsconfirmation') {
              await addServerOptIn();
            }
            if (pageData.tags.script) {
              pageData.tags.deferScript = pageData.tags.script.filter((script) => script.defer);
              pageData.tags.script = pageData.tags.script.filter((script) => !script.defer);
            }
          }
        }
        debugDetails[0].spent = Date.now() - debugDetails[0].spent;
        return {
          debugDetails,
          pageData,
          hiddenValue: [
            `using api url=${context.store.getters.API_URL}`,
            `NEWRELIC_APP_NAME-${NEWRELIC_APP_NAME}-`,
            `API_URL-${API_URL}`,
          ].join('_'),
        };
      } catch (e) {
        console.log(e);
        return await errorHandler(context, e, 404);
      }
    },
  };
